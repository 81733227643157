import { memo } from 'react';
import { SerializedComponentItem } from 'types/contentful';

import { FeatureBullet, FeatureBulletProps } from './FeatureBullet';

type Props = {
  featureBullets: SerializedComponentItem[];
} & ParentClassNameProp &
  Pick<FeatureBulletProps, 'as'>;

const FeatureBullets = memo(({ featureBullets, className, as }: Props) => {
  return (
    <div className={className}>
      {featureBullets.map((feature) => (
        <FeatureBullet as={as} entry={feature} key={feature.id} />
      ))}
    </div>
  );
});

FeatureBullets.displayName = 'FeatureBullets';

export { FeatureBullets };
