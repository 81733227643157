import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const ClearanceIcon = React.memo((props: GlyphProps) => (
  <Glyph width={38} height={38} {...props}>
    <g filter="url(#filter0_d_clearanceicon)">
      <circle cx="19" cy="18" r="16" fill="white" />
      <circle cx="19" cy="18" r="15" stroke="#D12702" strokeWidth="2" />
    </g>
    <path
      d="M13.1181 24.0001H11.2991V14.8541H10.2451V13.3921C10.9138 13.3581 11.4011 13.0974 11.7071 12.6101H13.1181V24.0001Z"
      fill="black"
    />
    <path
      d="M23.1344 19.7501C23.1344 20.8834 22.6698 21.9091 21.7404 22.8271C20.8224 23.7451 19.6438 24.2041 18.2044 24.2041C17.1958 24.2041 16.2664 23.9888 15.4164 23.5581L16.4534 21.9261C16.9861 22.1981 17.5754 22.3341 18.2214 22.3341C19.0601 22.3568 19.7911 22.0848 20.4144 21.5181C21.0491 20.9514 21.3664 20.3508 21.3664 19.7161C21.3778 19.1721 21.1908 18.7131 20.8054 18.3391C20.4314 17.9651 19.9554 17.7781 19.3774 17.7781C18.6408 17.7668 17.9834 18.1011 17.4054 18.7811L15.7904 18.1691L15.9774 12.6101H22.5224V14.4121H17.7284L17.6604 16.3671C18.2158 16.0724 18.7824 15.9251 19.3604 15.9251C20.4371 15.9478 21.3324 16.3161 22.0464 17.0301C22.7718 17.7441 23.1344 18.6508 23.1344 19.7501Z"
      fill="black"
    />
    <path
      d="M28.3603 13.816H26.9653V19H26.0113V13.816H24.6343V12.97H28.3603V13.816Z"
      fill="black"
    />
    <defs>
      <filter
        id="filter0_d_clearanceicon"
        x="0"
        y="0"
        width="38"
        height="38"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </Glyph>
));

ClearanceIcon.displayName = 'ClearanceIcon';

export { ClearanceIcon };
