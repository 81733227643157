// fastest possible memory cache
const imageCache = Object.create(null);

export const preloadImage = (url: string): string => {
  if (!url) {
    return null;
  }

  // preload image behind the scenes
  if (process.browser && !imageCache[url]) {
    imageCache[url] = new Image();
    imageCache[url].src = url;
  }

  return url;
};
