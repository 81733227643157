import { stringify } from 'qs';
import { truckMapConfig } from 'truckMapConfig';
import { SerializedComponentItem } from 'types/contentful';

import { normalizeS3Url } from './getS3Url';
import { preloadImage } from './preloadImage';

export type StaticImageType = 'local' | 's3' | 'map' | 'cms';
export type StaticImageFormat = 'avif' | 'webp' | 'png' | 'jpg' | 'force-avif';
export type StaticImageConfig = {
  src: string;
  width?: number;
  height?: number;
  quality?: number;
  format?: StaticImageFormat;
  type?: StaticImageType;
};
const staticImageFolderMap = new Map<StaticImageType, string>([
  ['local', truckMapConfig.localImagePath],
  ['s3', truckMapConfig.s3Path],
  ['map', truckMapConfig.mapImagePath],
  ['cms', truckMapConfig.cmsImagePath]
]);

export function getStaticImageUrl(src: string, type: StaticImageType = 'local', suffix = '') {
  const relativePath = type === 's3' ? normalizeS3Url(src) : src;
  const path = `${staticImageFolderMap.get(type)}${relativePath}${suffix}`;

  return preloadImage(`${truckMapConfig.staticCDN}/assets/${path}`);
}

export function getStaticOptimizedImageUrl({
  src,
  quality = 80,
  type = 'local',
  format = 'avif',
  preload = false,
  ...optimizerConfig
}: StaticImageConfig & { preload?: boolean }) {
  const relativePath = type === 's3' ? normalizeS3Url(src) : src;
  const path = `${staticImageFolderMap.get(type)}${relativePath}`;
  const params = stringify({
    path,
    format,
    quality,
    ...optimizerConfig
  });
  const url = decodeURIComponent(`${truckMapConfig.staticCDN}/image?${params}`);

  return preload ? preloadImage(url) : url;
}

const contentfulSpaceIdImageFolder = truckMapConfig.apiKeys.contentful.space;

export const getContentfulNormalizedPath = (src: string) =>
  src.split(contentfulSpaceIdImageFolder)[1].slice(1);

export const getContentfulImageUrl = (config: StaticImageConfig) => {
  if (!config.src) {
    return null;
  }

  const normalizedPath = getContentfulNormalizedPath(config.src);

  return getStaticImageUrl(
    normalizedPath,
    'cms',
    // we optimize on contentful CDN for better results
    normalizedPath.endsWith('.png') ? '?fm=webp&q=80' : ''
  );
};

export const getGetAssetUrl = (entry: SerializedComponentItem) =>
  getContentfulImageUrl({ src: (entry?.assets?.length && entry.assets[0]?.url) ?? null });
