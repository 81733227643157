import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const NoTruckAccessIcon = React.memo((props: GlyphProps) => (
  <Glyph width={38} height={38} {...props}>
    <g filter="url(#filter0_d_noaccess)">
      <circle cx="19" cy="18" r="16" fill="white" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 34C27.8366 34 35 26.8366 35 18C35 9.16344 27.8366 2 19 2C10.1634 2 3 9.16344 3 18C3 26.8366 10.1634 34 19 34ZM19 32C26.732 32 33 25.732 33 18C33 10.268 26.732 4 19 4C11.268 4 5 10.268 5 18C5 25.732 11.268 32 19 32Z"
      fill="#D12702"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 11C10.4477 11 10 11.4477 10 12V22C10 22.5523 10.4477 23 11 23H21C21.5523 23 22 22.5523 22 22V19.5V12C22 11.4477 21.5523 11 21 11H11ZM24 15C23.4477 15 23 15.4477 23 16V22C23 22.5523 23.4477 23 24 23H27C27.5523 23 28 22.5523 28 22V18.3028C28 18.1054 27.9416 17.9123 27.8321 17.7481L26.2969 15.4453C26.1114 15.1671 25.7992 15 25.4648 15H24Z"
      fill="black"
    />
    <circle cx="13.5" cy="23.5" r="2" fill="black" stroke="white" />
    <circle cx="25.5" cy="23.5" r="2" fill="black" stroke="white" />
    <path d="M8.5 7.5L30 29" stroke="#D12702" strokeWidth="2" />
    <defs>
      <filter
        id="filter0_d_noaccess"
        x="0"
        y="0"
        width="38"
        height="38"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </Glyph>
));

NoTruckAccessIcon.displayName = 'NoTruckAccessIcon';

export { NoTruckAccessIcon };
