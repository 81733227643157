import { TruckOptionsSlugs } from 'models/RoutePlannerModel';

import { getStaticOptimizedImageUrl, StaticImageConfig } from './getImageUrl';

const bucket = 'https://truckmap.s3.amazonaws.com';

export function normalizeS3Url(url: string) {
  return url.startsWith(bucket) ? url.replace(bucket, '').slice(1) : url;
}

export function getS3Url(url: string, config?: Omit<StaticImageConfig, 'src'>) {
  const src = normalizeS3Url(url);

  return getStaticOptimizedImageUrl({ src, type: 's3', ...config });
}

export function getS3IconUrlByTruckOption(slug: TruckOptionsSlugs) {
  return `mobile_assets/local/settings/${slug}.png`;
}
