import { ContentfulDataType, ContentfulDataTypeCollection } from '@truckmap/common';
import { useMemo } from 'react';

export function useDataTypeMap(dataTypeCollection: ContentfulDataTypeCollection) {
  return useMemo(
    () =>
      new Map<string, ContentfulDataType>(
        dataTypeCollection.items.map((item) => [item.slug, item])
      ),
    [dataTypeCollection]
  );
}
