import { mapEntriesByType } from 'lib/contentful/mapEntriesByType';
import { useMemo } from 'react';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';

/**
 * Takes a UIComponent from contentful and organizes/serializes the items.
 * @param input UIComponent
 * @returns Entries serialized and organized by type.
 */
const useEntries = (entries: SerializedComponentItem[]) => {
  const items = useMemo(() => mapEntriesByType(entries), [entries]);

  return { items };
};

export { useEntries };
