import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const CheckIcon = React.memo((props: GlyphProps) => (
  <Glyph width={12} height={12} {...props}>
    <path
      d="M1 7L5 11L11 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Glyph>
));

CheckIcon.displayName = 'CheckIcon';

export { CheckIcon };
